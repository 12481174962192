@import 'abstracts';

cap-input {
  div.p-inputgroup {
    input.p-inputtext {
      font-size: 14px;
      color: $primary;
      width: 100%;
      @include filledStyles();

      &:disabled {
        color: $text-semi-transparent;
      }

      &::-webkit-input-placeholder { /* stylelint-disable-line */
        font-weight: normal;
      }

      &.placeholder-semi-bold {
        @include setPlaceholderStyles($body-color, 600);
      }

      @include media-breakpoint-between(xs, md) {
        font-size: 16px;
      }

      &:hover,
      &:enabled:hover:not(.p-state-error),
      &:enabled:focus:not(.p-state-error) {
        font-size: 14px;

        @include media-breakpoint-between(xs, md) {
          font-size: 16px;
        }
      }

      &.transparent {
        background: transparent;
        border: none;
        cursor: text;
        padding: 0;
      }

      &.readonly {
        background: transparent;
        cursor: text;
      }

      &.search {
        padding-left: 50px;

        &:hover,
        &:enabled:hover:not(.p-state-error),
        &:enabled:focus:not(.p-state-error) {
          padding-left: 50px;
        }
      }
    }
  }

  &.input-filter {
    width: 150px;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    div.p-inputgroup {
      border: 1px solid $gray;
      border-radius: 20px;
      height: 38px;
      transition: border-color 0.2s;

      .p-inputgroup-addon {
        border: 0;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        color: var(--cap-primary-color);
        padding: 0;
        padding-right: 0;
        padding-left: 10px;
        line-height: 36px;

        i {
          opacity: 0.5;
        }
      }

      input.p-inputtext.input-filter {
        box-sizing: border-box;
        font-family: var(--primary-font);
        color: var(--primary-text);
        line-height: 38px;
        padding: 0 5px !important; /* stylelint-disable-line */
        background: $white;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 36px;
        border: 0;

        &:focus,
        &:hover {
          height: 36px;
          border: 0;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }

      &:hover {
        border-color: var(--cap-primary-color);
      }
    }
  }
}

.g-field {
  margin-bottom: 20px;

  label {
    font-size: rem-calc(14px);
    font-weight: 600;
    margin-bottom: 10px;
    display: inline-block;
  }

  .center {
    line-height: 36px;

    label {
      margin: 0;
    }
  }
}

.search-input {
  position: relative;

  i {
    @include setAbsoluteVerticalCenter();
    left: 15px;
    font-size: 18px;
  }

  cap-validator {
    display: none;
  }
}

.nav-bar-search cap-input cap-validator {
  display: none;
}

cap-validator .validator:not(.show) {
  display: none;
}

@include media-breakpoint-down(md) {
  cap-validator .validator.show {
    margin-bottom: 25px;
  }
}

.no-validator {
  cap-validator .validator {
    display: none;
  }
}

.text-between-field {
  font-size: 14px;
  color: $body-color;
  margin-top: 10px;
}

label {
  margin-left: 0;
}

body cap-input.tab-focus input.p-inputtext:enabled:focus {
  @include tabFocus();
}

@include media-breakpoint-down(md) {
  cap-input-mask {
    .p-inputgroup input.p-inputtext {
      width: 100%;
    }
  }

  cap-input-number {
    .p-inputgroup input.p-inputnumber-input {
      width: 100%;
      text-align: center;
      border-radius: unset;
    }

    &.disabled {
      .p-inputgroup input.p-inputnumber-input {
        background: $white;
      }
    }

    .p-inputnumber-buttons-horizontal {
      &.p-button.p-inputnumber-button-up {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .p-inputgroup .p-button:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
