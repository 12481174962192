@import 'abstracts';

body .p-paginator {
  color: $body-color;
  display: block;
  border: none;
  float: right;
  background-color: inherit;
  text-align: right;
  width: 100%;
  padding: 15px 0;
  position: relative;
  font-size: 1rem;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  .p-paginator-first,
  .p-paginator-last {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  .p-paginator-next {
    background: $white;
    border: 1px solid $border-color;
    border-left: none;
    padding: 10px;
    font-size: 14px;

    &:not(.p-disabled) {
      color: var(--cap-primary-color);
    }

    .p-paginator-icon:before {
      @include setFontAwesomeProIcon('\f054');
    }
  }

  .p-paginator-prev {
    background: $white;
    border: 1px solid $border-color;
    border-right: none;
    padding: 10px;
    font-size: 14px;

    &:not(.p-disabled) {
      color: var(--cap-primary-color);
    }

    .p-paginator-icon:before {
      @include setFontAwesomeProIcon('\f053');
    }
  }

  .p-paginator-pages {
    .p-paginator-element {
      color: var(--cap-primary-color);
      padding: 0 7px;
      align-items: center;
      border: 1px solid $border-color;
      border-right: none;
      background-color: $white;
      font-size: 14px;
      font-weight: normal;

      &:last-child {
        border-right: 1px solid $border-color;
      }

      &.p-state-active {
        background: var(--cap-primary-color);
        color: $white;
      }

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

body .p-disabled,
body .p-component:disabled {
  box-shadow: none;
}

.p-paginator {
  .p-paginator-element {
    anglelefticon,
    anglerighticon {
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  .p-paginator-pages .p-paginator-page,
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    height: 34px !important; /* stylelint-disable-line declaration-no-important */
  }
}

.pagination__per-page {
  font-size: 0.88rem;

  cap-dropdown {
    .cap-dropdown.p-dropdown {
      height: 34px !important; /* stylelint-disable-line declaration-no-important */
    }

    .cap-dropdown.p-dropdown .p-dropdown-label {
      height: 32px !important; /* stylelint-disable-line declaration-no-important */
      padding-right: 0;
    }
  }

  .p-dropdown-trigger {
    .p-dropdown-trigger-icon {
      font-size: 0.88rem;
    }
  }

  .pagination__changer {
    height: 34px !important; /* stylelint-disable-line declaration-no-important */
  }
}

.pagination--skeleton {
  width: 100%;

  :first-child {
    display: flex;
    justify-content: space-between;
  }
}

.cap-info-table.p-datatable .p-paginator .paginator-left__page-text {
  margin-left: 0;
}

