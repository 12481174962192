@import 'abstracts';

cap-multi-select {
  i {
    opacity: 0.5;
  }
}

.p-multiselect-panel .p-multiselect-header {
  border-radius: 3px;
}
