@import 'abstracts';

cap-calendar {
  span.cap-calendar.p-calendar {
    input.p-inputtext {
      @include filledStyles();
      @include straightLeft(3px);

      &::-webkit-input-placeholder { /* stylelint-disable-line */
        font-weight: 600;
      }
    }
  }

  .p-calendar-timeonly {
    width: 100%;

    input {
      width: 100%;
      height: 38px;
      border: 1px solid $gray;

      &:hover,
      &:focus {
        outline: 0;
        border-color: $gray !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }

  .p-datepicker-timeonly {
    .p-timepicker {
      display: none;
    }
  }

  .p-calendar-w-btn .p-datepicker-trigger.p-button {
    .pi-calendar:before {
      @include setFontAwesomeProIcon('\f073', 600);
    }
  }
}

.plane-calendar {
  cap-calendar {
    .p-calendar-w-btn .p-datepicker-trigger.p-button {
      .pi-calendar:before {
        @include setFontAwesomeProIcon('\f5b0', 600);
      }
    }
  }
}

cpb-form-renderer {
  cap-timepicker {
    .content {
      display: flex;
      justify-content: center;
      line-height: 3;
    }
  }
}

cap-timepicker {
  .content {
    .p-inputtext:enabled:focus:not(.p-state-error) {
      border-right: 1px solid $timepicker-color !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.p-datepicker {
  min-width: unset !important; /* stylelint-disable-line declaration-no-important */
  border-radius: 3px;

  table {
    td {
      padding: 0.5em;
    }

    th.p-datepicker-weekheader,
    td.p-datepicker-weeknumber {
      background: transparent;
      box-shadow: none;
      padding: 0.5em;
      border-right: 1px solid $timepicker-color;

      span.p-disabled {
        background: transparent;
        box-shadow: none;
      }
    }
  }
}

body cap-calendar.tab-focus p-calendar span.cap-calendar.p-focus {
  @include tabFocus();
}

.content .form-group {
  .p-calendar.p-calendar-w-btn>.p-inputtext,
  .p-calendar.p-calendar-w-btn>.p-inputtext:hover,
  .p-calendar.p-calendar-w-btn>.p-inputtext.p-focus {
    height: 38px !important; /* stylelint-disable-line declaration-no-important */
  }

  .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    height: 38px !important; /* stylelint-disable-line declaration-no-important */
  }
}
