@import 'abstracts';

.full-mobile-width {
  margin: 0 -$mobile-horizontal-gutters;
  position: relative;
}

.mobile--bottom-call-to-action {
  position: fixed;
  bottom: 0;
  padding: 15px 25px;
  box-shadow: 0 -5px 13px 0 rgba(0, 0, 0, 0.15);
  background-color: $white;
  min-height: rem-calc(68);
  margin-left: -25px;
  width: 100%;
  z-index: 2;
}

.mobile-primary-action-icon {
  color: $primary;
  font-size: 24px;
}

.mobile-big-icon {
  font-size: 24px;
}

.mobile-subtitle {
  font-size: 16px;
  margin-bottom: 22px;
}

.info-field {
  margin-top: 8px;
  font-size: 14px;

  > div:first-child {
    font-weight: 600;
    margin-bottom: 4px;
  }
}
