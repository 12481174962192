/* stylelint-disable */
@font-face {
  font-family: 'icomoon';
  src: url('icomoon.eot?fo4tqc');
  src: url('icomoon.eot?fo4tqc#iefix') format('embedded-opentype'),
  url('icomoon.ttf?fo4tqc') format('truetype'),
  url('icomoon.woff?fo4tqc') format('woff'),
  url('icomoon.svg?fo4tqc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'], [class*=' icon-'] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-farmer:before {
  content: '\e900';
}

/* stylelint-enable */
