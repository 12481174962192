@import 'abstracts';

body cap-dropdown.tab-focus p-dropdown .cap-dropdown.p-focus {
  @include tabFocus();
}

// Adjust text-position when no results found
.p-dropdown-empty-message,
.p-multiselect-empty-message {
  padding: 10px;
  font-weight: normal;
}

.cap-dropdown.p-dropdown {
  border-radius: 3px;

  .p-dropdown-label {
    border-radius: 3px;
  }

  &.fw-n-placeholder .p-dropdown-label.p-placeholder {
    font-weight: normal;
  }

  .p-dropdown-trigger {
    @include straightRight(3px);
  }

  // FIX: Clear icon position
  &.p-dropdown:not(.p-disabled) .p-dropdown-clear-icon,
  &.p-dropdown:not(.p-disabled):hover .p-dropdown-clear-icon,
  &.p-dropdown:not(.p-disabled).p-focus .p-dropdown-clear-icon {
    position: absolute;
    right: 2em;
    top: 50%;
    font-size: 1em;
    height: 1em;
    margin-top: -0.5em;
    margin-right: 0.5em;
    font-weight: bold;
  }
}

// Overwrite default styling of
cap-dropdown .cap-dropdown.p-dropdown.date-range-dropdown,
.cap-dropdown.p-dropdown.date-range-dropdown:not(.p-disabled):hover,
.cap-dropdown.p-dropdown.date-range-dropdown:not(.p-disabled).p-focus {
  width: auto;
  border: none;
  background: transparent;
  color: $primary;
  position: absolute;
  right: 10px;
  top: 0;

  .p-dropdown-label {
    background: transparent;
    color: $primary;
    font-weight: 600;
    border: none;
    padding-top: 10px;
    text-align: right;
    text-decoration: underline;
  }

  .p-dropdown-trigger {
    background: transparent;
    border: none;
    color: $primary;
  }
}

@media screen and (max-width: 768px) {
  cap-dropdown .cap-dropdown.p-dropdown.date-range-dropdown,
  cap-dropdown .cap-dropdown.p-dropdown.date-range-dropdown:not(.p-disabled):hover,
  cap-dropdown .cap-dropdown.p-dropdown.date-range-dropdown:not(.p-disabled).p-focus {
    position: relative;
    top: 0;
    right: 0;
    float: right;
    z-index: 100;
  }
}

.cap-dropdown-panel.p-dropdown-panel,
.cap-multi-select-panel.p-multiselect-panel,
.cap-dropdown-panel.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-container,
.cap-dropdown-panel.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-container .p-dropdown-filter {
  border-radius: 3px;
}

.label-on-right .cap-dropdown {
  &.p-dropdown,
  &.p-dropdown:not(.p-disabled):hover {
    span.p-dropdown-label {
      padding-right: 0;
      padding-left: 3em;
    }
  }
}

.pagination__changer .cap-dropdown {
  &.p-dropdown,
  &.p-dropdown:hover {
    span.p-dropdown-label {
      padding-right: 5px !important; /* stylelint-disable-line declaration-no-important */
      padding-left: 5px;
      padding-top: 8px;
      color: var(--primary-text);
    }
  }
}
