$flags: ad, ae, af, ag, ai, al, am, an, ao, aq, ar, as, at, au, aw, az, ba, bb, bd, be, bf, bg, bh, bi, bj, bm, bn, bo,
  br, bs, bt, bv, bw, by, bz, ca, cc, cd, cf, cg, ch, ci, ck, cl, cm, cn, co, cr, cu, cv, cx, cy, cz, de, dj, dk, dm, do,
  dz, ec, ee, eg, eh, er, es, et, fi, fj, fk, fm, fo, fr, ga, gd, ge, gf, gh, gi, gl, gm, gn, gp, gq, gr, gs, gt, gu, gw,
  gy, hk, hm, hn, hr, ht, hu, id, ie, il, in, io, iq, ir, is, it, jm, jo, jp, ke, kg, kh, ki, km, kn, kp, kr, kw, ky, kz,
  la, lb, lc, li, lk, lr, ls, lt, lu, lv, ly, ma, mc, md, me, mg, mh, mk, ml, mm, mn, mo, mp, mq, mr, ms, mt, mu, mv, mw,
  mx, my, mz, na, nc, ne, nf, ng, ni, nl, no, np, nr, nu, nz, om, pa, pe, pf, pg, ph, pk, pl, pm, pn, pr, pt, pw, py, qa,
  re, ro, rs, ru, rw, sa, sb, sc, sd, se, sg, sh, si, sj, sk, sl, sm, sn, so, sr, ss, st, sv, sy, sz, tc, td, tf, tg, th,
  tj, tk, tl, tm, tn, to, tp, tr, tt, tv, tw, ty, tz, ua, ug, uk, um, us, uy, uz, va, vc, ve, vg, vi, vn, vu, wf, ws, ye,
  za, zm, zr, zw;
$step: 0.413223; /* stylelint-disable-line number-max-precision */

span.flag {
  width: 44px;
  height: 30px;
  display: inline-block;
}

img.flag {
  width: 30px;
  height: 20px;
  border-radius: 5px;
}

.flag {
  background: url('/assets/flags/flags_responsive.png') no-repeat;
  background-size: 100%;
  vertical-align: middle;
}

@each $flag in $flags {
  $i: index($flags, $flag);

  .flag-#{$flag} {
    background-position: 0 percentage(($step * $i / 100));
  }
}

.flag-gb,
.flag-uk,
.flag-en {
  background-position: 0 92.561983%; /* stylelint-disable-line number-max-precision */
}
