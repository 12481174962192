@import 'abstracts';

cap-textarea {
  textarea.cap-textarea {
    @include filledStyles();
    min-height: 38px;

    &::-webkit-input-placeholder { /* stylelint-disable-line */
      font-weight: normal;
    }

    @include media-breakpoint-between(xs, md) {
      font-size: 16px;
    }
  }
}

body cap-textarea.tab-focus textarea.cap-textarea:focus {
  @include tabFocus();
}
