@import 'abstracts';

.p-dialog.p-confirm-dialog {
  border-radius: 5px;
  background-color: $white;
  padding: 1em;

  .p-dialog-titlebar,
  .p-dialog-content,
  .p-dialog-footer {
    border: none;
    border-radius: inherit;
    color: $primary;
    padding: 1em;

    p-footer {
      @include flexCentering();

      /* Yes/No buttons tend to be kind of small */
      .cap-button {
        min-width: 6em;
      }
    }
  }

  .p-dialog-titlebar {
    @include straightBottom();
    font-family: $font-semi-bold;

    .p-dialog-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .p-dialog-content {
    .p-confirm-dialog-message {
      width: 100%;
      color: var(--primary-text);
    }
  }
}
