@import 'abstracts';

.ut-divider-right {
  border-right: 1px solid $gray;
}

.ut-divider-left {
  border-left: 1px solid $gray;
}

.ut-divider-top {
  border-top: 1px solid $gray;
}

.ut-divider-bottom {
  border-bottom: 1px solid $gray;
}
