@import 'abstracts';

.equal-flex-col {
  flex: 1;
  display: flex;
  align-items: center;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.flex-center {
  display: flex;
  justify-content: center;
  text-align: center;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  flex-grow: 1;
  flex-shrink: 0;
}
