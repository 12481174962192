@import 'abstracts';

.grade-split-wrapper {
  .grade-split-header {
    font-size: rem-calc(13);
    color: $text-semi-transparent;

    &:not(.no-border) {
      border-bottom: 1px solid $gray;
    }

    .col:not(:first-child) {
      border-left: 1px solid $gray;
    }
  }

  .grade-split-body {
    font-size: rem-calc(13);
    color: $dark-gray;

    .col:not(:first-child) {
      border-left: 1px solid $gray;
    }
  }
}
