@import 'abstracts';

.action-cards {
  @include setBottomGutters(6px);

  app-action-card {
    display: block;
  }

  .action--transparent {
    padding: 0;
    box-shadow: none;
    border: 0;
    position: relative;
    background: none;
  }

  .action__card {
    @include card-layout(13px 26px 13px 13px);
    box-shadow: none;
    border: 1px solid $gray;
    position: relative;

    .action__circle {
      @include flexCentering;
      $size: 30px;
      width: $size;
      height: $size;
      border: 1px solid $primary;
      border-radius: 50%;

      i {
        font-size: 13px;
        color: $primary;
      }

      &--full {
        background: $primary;

        i {
          color: $white;
        }
      }
    }

    .action__content {
      display: flex;
      align-items: center;
      margin-left: 10px;
      width: calc(100% - 40px);
    }

    .action__filled {
      padding-left: 40px;

      p {
        font-size: 12px;
        line-height: 15px;
        padding-right: 15px;
      }
    }

    .action__preview {
      padding-right: 15px;
      margin-top: 20px;
    }

    &:after {
      @include setFontAwesomeProIcon('\f054');
      @include setAbsoluteVerticalCenter();
      right: 13px;
      font-size: 13px;
      color: $text-semi-transparent;
    }

    &.action--disabled {
      position: relative;
      opacity: 0.5;
    }

    &.action--pointer {
      cursor: pointer;
    }

    &.action--error {
      border-color: $red;

      .action__circle {
        border-color: $red;
        background: $red;

        i {
          color: $white;
        }
      }
    }

    &.action--warning {
      border-color: $gray;
    }

    &.action--readonly {
      padding: 13px;

      &:after {
        display: none;
      }
    }

    .primary:not(button) {
      color: $primary;
    }

    button.primary {
      color: $white;
      text-decoration: none;
    }
  }

  .action__overlay {
    @include setAbsoluteCenter();
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .action__card--checked {
    @extend .action__card;
    border: 1px solid $green;
  }
}

p.action__horizontal-separator {
  overflow: hidden;
  text-align: center;
  font-size: 13px;
  color: $primary-bright;
  margin: 20px 0;

  &:before,
  &:after {
    background-color: var(--third-color);
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}
