@import 'abstracts';

.scanner-message {
  text-align: center;
  margin-bottom: 20px;

  p {
    font-size: 16px;

    i {
      margin-right: 5px;
      color: $green;
    }

    span {
      color: $primary;
      font-weight: bold;
    }
  }
}
