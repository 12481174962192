@import 'abstracts';

div.checkbox-right {
  display: inline-flex;
  border-color: var(--cap-primary-color);
  width: 100%;
  border: 1px solid $gray;
  border-radius: 20px;
  height: calc(38px - 2px);
  padding: 10px 15px;
  transition: border-color 0.2s;
  background: $white;

  &:hover {
    border-color: var(--cap-primary-color);
  }

  i {
    color: var(--cap-primary-color);
    opacity: 0.5;
  }

  p {
    padding: 0 14px;
    font-weight: 600;
    color: var(--primary-text);
  }
}
