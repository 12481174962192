:root {
  --et-delimiter-color: #ddd;
  --et-gutter-border-size: 10px;
  --et-gutter-border-color: #f9f9f9;
  --et-header-color: rgba(58, 58, 58, 0.5);
  --et-row-background: #fff;
  --et-row-border-color: transparent;
  --et-row-padding: 12.5px;
}

// Abstracts
@import 'abstracts';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

/* Base */
@import './base/reset';
@import './base/default-capturum-ui';
@import './base/fonts';
@import './base/typography';
@import './base/alignment';
@import './base/colors';
@import './base/relative-positions';
@import './base/layout';
@import './base/dividers';

/* Vendor */
@import './vendor/bootstrap';
@import './vendor/capturum-ui';
@import './vendor/capturum-login';
@import './vendor/capturum-complete';
@import './vendor/font-awesome';
@import './vendor/icomoon/icomoon';

/* Components */
@import './components/alert';
@import './components/buttons';
@import './components/split-buttons';
@import './components/links';
@import './components/inputs';
@import './components/textarea';
@import './components/calendar';
@import './components/table';
@import './components/cell-table';
@import './components/week-table';
@import './components/file-uploader';
@import './components/dialog';
@import './components/confirm-dialog';
@import './components/selects';
@import './components/form-layout.scss';
@import './components/form';
@import './components/ui-tabview';
@import './components/radio-buttons';
@import './components/mobile-cards';
@import './components/paginator';
@import './components/harvest-card';
@import './components/radio-row';
@import './components/scanner-popup';
@import './components/menu-items';
@import './components/dropdowns';
@import './components/autocomplete';
@import './components/accordion-card';
@import './components/segmented-button';
@import './components/checkables';
@import './components/color-picker';
@import './components/dashboard-cards';
@import './components/checkbox';
@import './components/spinner';
@import './components/multiselect';
@import './components/dynamic-accordion';
@import './components/checkbox-right';
@import './components/scanner-message';
@import './components/input-number-increment';
@import './components/input-switch';
@import './components/overlay-panel';
@import './components/info-table';
@import './components/builders';
@import './components/flags';

/* Layouts */
@import './layout/mobile';
@import './layout/gradings';
@import './layout/header';
@import './layout/capturum-login';
