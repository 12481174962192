@import 'abstracts';

.g-uploader {
  label {
    border: 3px dashed $uploader-border;
    border-radius: 7px;
    padding: 0;
    height: 70px;

    i {
      margin: 0;
    }
  }
}
