@import 'abstracts';

.content-spinner {
  div.p-progress-spinner {
    width: 2em;
    height: 2em;
  }

  .p-progress-spinner-circle {
    stroke: $primary !important; /* stylelint-disable-line declaration-no-important */
    stroke-width: 6;
  }
}

.content .content-spinner {
  @include flexCentering;
}
