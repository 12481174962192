@import 'abstracts';

.input-number-increment {
  .icon.cap-button.secondary {
    border-color: $gray;
    height: 100%;
    max-height: 38px;
    max-width: 38px;
    width: 38px;
    padding: 0;

    &.minus {
      @include straightRight();
      border-right: 0;
    }

    &.plus {
      @include straightLeft();
      border-left: 0;
    }

    .p-button-label {
      display: none;
    }
  }

  @include inputNumberHideArrows();
}

body .input-number-increment cap-input {
  width: auto;
  flex-grow: 1;
  text-align: center;

  div.p-inputgroup input.p-inputtext,
  div.p-inputgroup input.p-inputtext:enabled:focus:not(.p-state-error),
  div.p-inputgroup input.p-inputtext:hover,
  div.p-inputgroup input.p-inputtext:enabled:hover:not(.p-state-error) {
    border-radius: 0;
  }
}
