@import 'abstracts';

/* Overwrite button-icons to not have padding so spinner does not spiral out of control */
.p-button-icon-left {
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
}

/* Overwrite filter buttons to not have padding because they don't have icons */
div.cap-table-filters.table-filters-row-wrap:last-child {
  button.cap-button span.p-button-text.p-clickable {
    padding: 0.43em 1em;
  }
}

button {
  &.btn {
    &.btn-outline-primary:not(:hover) {
      background-color: $white;
      color: var(--cap-primary-color);
    }
  }
}

cap-button {
  span.p-button-text.p-clickable {
    padding: 0.55em 0.55em 0.55em 1.55em;
  }

  button.cap-button {
    padding: 2px 10px;

    &:not(:disabled) {
      font-size: 14px;
      box-shadow: 0 2px 4px 0 rgba(99, 49, 137, 0.24);

      &.large {
        font-size: 18px;
      }

      &.success,
      &.error,
      &.danger {
        border-width: 1px;
      }
    }

    &.primary {
      border-radius: 3px; // emendis-ui override .primary issue

      &:not(:disabled) {
        box-shadow: 0 2px 4px 0 rgba(99, 49, 137, 0.24);
      }
    }

    &.primary:disabled {
      background: var(--cap-primary-color);
      border: 1px solid var(--cap-primary-color);
    }

    &.primary:disabled,
    &.secondary:disabled {
      font-size: 0.88rem;
    }

    &.primary-outline,
    &.secondary:not(:disabled),
    &.primary:not(:disabled) {
      border-width: 1px;
    }

    &.primary-outline:not(:hover) {
      background-color: $white;
      color: var(--cap-primary-color);
    }

    &.no-border {
      border: 0 !important; /* stylelint-disable-line declaration-no-important */
    }

    &.body-background {
      background-color: $body-background !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

button.cap-button {
  @include setLeftGutters(0.6em);
  line-height: 1;
  font-weight: 600;
  display: flex;
  justify-content: center;

  &.secondary-button:not(:disabled) {
    background-color: var(--text-color-4);
    color: var(--cap-primary-color);
    box-shadow: 0 1px 2px 0 var(--cap-primary-color);
    border: 1px solid var(--cap-primary-color);
    padding-top: 0.6em;
    padding-bottom: 0.6em;
    border-radius: 3px;

    &:hover {
      color: $white;
      background-color: var(--cap-primary-color);
    }
  }

  &.p-button.error,
  &.p-button.danger {
    background-color: var(--error-color);
    box-shadow: 0 2px 4px 0 $danger-button-box-shadow;
    color: var(--text-color-4);
    border-color: var(--error-color);
  }
}

div.p-inputswitch span.p-inputswitch-slider:before {
  height: 21px;
  width: 19.8px;
  left: 0.10em;
  top: 9px;
  bottom: 0;
}

.cap-button {
  &.primary:not(:disabled),
  &.secondary:not(:disabled),
  &.danger:not(:disabled),
  &.primary:enabled,
  &.secondary:enabled,
  &.danger:enabled {
    &:hover {
      text-decoration: none !important; /* stylelint-disable-line declaration-no-important */

      .p-button-label {
        text-decoration: underline;
      }
    }
  }

  &.p-button {
    padding: 4px 24px;

    &.p-button-label-icon-left {
      padding-left: 14px;

      .p-button-label {
        padding-left: 10px;
      }
    }
  }
}

.btn-min-width {
  min-width: 144px;
}

body cap-button {
  p-button {
    &.p-disabled {
      background: none;
      opacity: 1;
    }
  }
}
