@import 'abstracts';

.week-table {
  @include setBottomMargin($table-bottom-gutter);
  background-color: $white;

  .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: $white;
  }
}

$table-bottom-gutter: 50px;

.cell-table.p-datatable {
  .p-datatable-thead > tr > th {
    &.week-title {
      text-align: right;
      min-width: 220px;
    }

    &:not(.week-title) {
      word-spacing: 100vw;
    }
  }

  .p-datatable-tbody {
    tr {
      td {
        &.week-cell:first-of-type {
          border-left: none;
          vertical-align: middle;
        }

        &.week-cell {
          text-align: right;
          vertical-align: top;
          line-height: 38px;
        }
      }
    }
  }
}

cap-skeleton .loader {
  margin: 0;
}

.totals-cell,
.totals-title {
  width: 100px;
}

.toggle-cell,
.toggle-title {
  width: 70px;
}
