@import 'abstracts';

@include media-breakpoint-down(md) {
  .cap-multi-select-panel,
  .cap-dropdown-panel {
    left: 20px;
    width: calc(100% - 40px);

    .p-inputtext {
      font-size: 16px;
    }
  }
}

.p-dropdown-label:not(.p-placeholder) {
  @include filledStyles();
}
