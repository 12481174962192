@import 'abstracts';

body div.p-dialog.p-confirm-dialog {
  div.p-dialog-content,
  div.p-dialog-footer,
  p-footer {
    @include flexCentering();
  }
}

body .p-dialog {
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.2);

  .p-dialog-header {
    border: none;
    background-color: $white;
    text-align: center;
    padding: 1.2em;

    .p-dialog-title {
      font-size: 1.6em;
      font-weight: bold;
      line-height: 1.5em;
      color: $dark-gray;
    }

    .p-dialog-header-close-icon {
      width: 22px;
      height: 22px;
      color: $text-semi-transparent;
    }
  }

  .p-dialog-content {
    border: none;

    &.gray-body {
      background-color: $body-background;
    }
  }

  .p-dialog-footer {
    border: none;
    border-top: 1px solid $gray;

    cap-button {
      &:last-child {
        button {
          margin-right: 0;
        }
      }
    }

    p-footer {
      display: inline-flex;
      justify-content: center;
    }
  }

  &.p-confirm-dialog {
    width: 30em;

    .p-dialog-content {
      text-align: center;
    }

    .p-dialog-footer {
      border: none;
    }
  }
}

.p-dialog-header {
  z-index: 1;

  /* Make sure titles are aligned centered */
  .p-dialog-title {
    width: 100% !important; /* stylelint-disable-line declaration-no-important */
  }
}

.responsive-dialog {
  @include responsiveDialog();
}

body .p-dialog.timeline-dialog {
  .p-dialog-header {
    z-index: 1;
  }

  .p-dialog-content {
    max-height: calc(100% - 50px) !important; /* stylelint-disable-line declaration-no-important */
    min-width: 40vw;
    max-width: 800px;
  }
}

.p-dialog {
  width: 90%;

  &.lg-40 {
    @include lg-40();
  }

  &.lg-50 {
    @include lg-50();
  }

  &.lg-60 {
    @include lg-60();
  }

  &.lg-70 {
    @include lg-70();
  }

  @include media-breakpoint-up(lg) {
    width: 70%;
  }

  @include media-breakpoint-up(xl) {
    width: 60%;
  }

  &.history-timeline-dialog {
    padding: 0 30px;
    background-color: $white;
    height: 80%;

    .p-dialog-content {
      height: calc(100% - 83px);
    }
  }
}

.form-dialog {
  @include media-breakpoint-up(md) {
    padding: 20px 80px;
    min-width: 450px;
  }

  .dialog-actions {
    @include setBottomGutters(15px);

    div.dialog-actions--row {
      @include flexCentering();
    }

    cap-button button {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }
}

p-dynamicdialog {
  .p-dynamic-dialog {
    &.padding-0 {
      @include dialog-padding-0();
    }
  }
}
