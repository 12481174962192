// Override Bootstrap
// Note: since v5
// Renamed .ml-* and .mr-* to .ms-* and .me-*.
// Renamed .pl-* and .pr-* to .ps-* and .pe-*.
// Renamed .text-left and .text-right to .text-start and .text-end.
.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}


.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}


.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.form-group {
  margin-bottom: 1rem;
}

.row {
  > cap-button {
    width: auto;
  }

  [class*='col-'] {
    position: relative;
  }
}
