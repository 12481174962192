@import 'abstracts';

cpb-list-renderer-filters {
  .table-filters.cap-table-filter {
    background-color: $body-background;
    align-items: flex-start;

    .actions {
      display: flex;
    }

    .cap-checkbox-filter-label {
      padding-left: 10px;
    }

    cap-dynamic-filters {
      .dynamic-multiselect-filter {
        .cap-multi-select.p-multiselect,
        .cap-multi-select.p-multiselect:not(.p-disabled),
        .cap-multi-select.p-multiselect:not(.p-disabled):hover,
        .cap-multi-select.p-multiselect:hover {
          .p-multiselect-label {
            display: flex;
            padding-left: 42px;
          }
        }

        .delete-filter {
          right: 35px;
        }
      }

      .dynamic-dropdown-filter {
        cap-dropdown {
          .cap-dropdown.p-dropdown,
          .cap-dropdown.p-dropdown:not(.p-disabled),
          .cap-dropdown.p-dropdown:not(.p-disabled):hover,
          .cap-dropdown.p-dropdown:hover,
          .p-dropdown-label {
            display: flex;
          }
        }

        .delete-filter {
          right: 35px;
        }
      }

      .cap-dynamic-checkbox-filter {
        p-checkbox {
          padding-left: 14px;
        }
      }

      .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
        padding: 0 0 0 7px !important; /* stylelint-disable-line declaration-no-important */
      }

      .p-calendar.p-calendar-w-btn input {
        padding-left: 16px !important; /* stylelint-disable-line declaration-no-important */
      }

      .cap-filters {
        justify-content: space-between;

        .filter {
          width: 240px;
          max-width: 250px;
        }

        .filter-button {
          .cap-button.tertiary:not(:disabled) {
            background-color: $white;
            border: 1px solid var(--cap-primary-color);
          }

          .refresh-filter-button {
            margin-right: 10px;
          }

          cap-button.refresh-filter-button > p-button > button {
            background-color: var(--cap-primary-color) !important;
            color: $white;
          }
        }
      }

      cap-multi-select p-multiselect .p-multiselect .p-multiselect-label,
      cap-dropdown p-dropdown .p-dropdown .p-dropdown-label {
        padding-top: 7px;
      }
    }
  }
}

.builder-detail-template__header-actions {
  display: flex;

  cap-button {
    margin-right: 5px;
  }
}

cpb-list-renderer {
  &:nth-child(1n) {
    .header,
    .cpb-filter-height-2 .p-datatable-thead cap-info-table-header > tr > th,
    .cpb-filter-height-3 .p-datatable-thead cap-info-table-header > tr > th {
      background: transparent;
      box-shadow: none;
    }
  }

  .th_totals-column {
    div {
      @include right-total-column();

      margin-right: 10px;
    }
  }

  &.white {
    cpb-list-renderer-filters .cap-table-filter {
      background-color: $white;
    }
  }

  .actions cap-button p-button button:not(:disabled) {
    border-radius: 17px;
    height: 36px;
    font-size: 14px;
    text-decoration: none;
    font-weight: bold;
  }

  &.info-table--outline {
    cap-info-table {
      .cap-info-table.p-datatable .p-datatable-tbody td.cap-info-table__actions-column--single {
        position: absolute;
        right: 0;
      }

      @include media-breakpoint-down(md) {
        .cap-info-table.p-datatable .p-datatable-tbody td .cap-info-table__actions-column--flexed {
          border-bottom: none;
        }
      }

      .cap-info-table.p-datatable {
        .p-datatable-thead {
          tr {
            th {
              font-weight: 600;
              color: $dark-gray;

              &.th_d-none {
                display: none;
              }
            }
          }
        }

        .p-datatable-tbody {
          tr {
            td {
              background: $body-background;
              border-top: 1px solid $gray;
              border-bottom: 1px solid $gray;
              height: 56px;
              font-size: 16px;

              &:first-of-type {
                border-left: 1px solid $gray;
              }

              &:last-of-type {
                border-right: 1px solid $gray;
              }

              &.display-full-text span {
                white-space: inherit;
                text-overflow: inherit;
              }

              &.totals-column {
                cap-cell-data {
                  @include right-total-column();
                }
              }
            }
          }
        }
      }
    }
  }

  .cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight {
    border-color: $green !important;
    background: $green !important;
  }

  cpb-list-renderer-paginator {
    .p-paginator {
      .p-paginator-next,
      .p-paginator-prev {
        font-size: 14px;
      }
    }
  }

  cpb-list-renderer-totals .totals-title {
    font-size: 1rem;
  }

  cpb-list-renderer-totals .totals-value {
    font-size: 1rem;

    @include right-total-column();
  }
}

.cap-calendar.cap-rounded.p-calendar input {
  border-color: $gray;
  border-radius: 20px !important;
  font-size: 0.9rem;
  font-weight: 600;
  height: 35px;
  min-width: 130px;
  padding-left: 39px;
}

div.filter-icon i {
  font-size: 13px;
}

cap-sidebar .cap-sidebar-footer > cap-button button.cap-button.success {
  position: absolute;
  right: 20px;
}

/* stylelint-disable */
.list_planned_batches_move_to_production_line,
.list_planned_batches_bulk_move_to_production_line,
.list_unplanned_batches_plan,
.list_unplanned_batches_bulk_plan {
  @include media-breakpoint-only(lg) {
    @include responsiveDialog();
  }

  @include lg-40();
  @include gray-body();
  @include dialog-padding-0();

  .p-dialog-content {
    overflow-y: unset;
  }
}

.list_lots_bulk_combine_lots,
.list_lots_bulk_confirm_lots,
.list_lots_confirm_lot {
  @include responsiveDialog();
  @include gray-body();
  @include dialog-padding-0();
  @include lg-50();

  cpb-list-renderer-filters {
    .cap-table-filter {
      display: none;
    }
  }

  cap-input {
    div.p-inputgroup {
      width: 80%;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1367px) {
    @include lg-60();
  }
}

.list_lots_edit_lot_characteristics {
  @include responsiveDialog();
  @include gray-body();
  @include dialog-padding-0();
  @include lg-50();
}

.list_unmatched_lots_match_to_shipment,
.list_lots_download_logistics_label {
  @include responsiveDialog();
  @include gray-body();
  @include dialog-padding-0();
  @include lg-40();
}

.list_shipment_batches_bulk_split_shipments {
  @include dialog-padding-0();
  @include lg-50();
}

cpb-config-list {
  div.justify-content-between { // Override the style from the builder package applied in the template
    justify-content: end !important;
  }

  div.flex-1 a {
    visibility: hidden;
  }

  .p-splitbutton {
    .p-button {
      width: unset;
    }

    .cap-splitbutton-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: $light-gray;
    }
  }
}

body .p-datatable .p-sortable-column.p-highlight {
  background-color: transparent;
  color: $dark-gray;
}

.builders-characteristics {
  span {
    white-space: pre-wrap !important;
    word-spacing: 5px;
  }
}

app-stock-order-form,
app-confirm-reception {
  .popup-content {
    formly-group > formly-field > cpb-group-input {
      display: flex;
      flex-wrap: wrap;

      formly-field {
        padding-right: 16px;
      }

      div.field-wrapper input {
        width: 92px;
      }
    }
  }
}

p-dynamicdialog {
  .list_stock_deliveries_edit_stock_order,
  .list_stock_deliveries_confirm_reception {
    width: 50% !important;
  }

  .list_product_freshness_split_date,
  .list_product_freshness_split_channels,
  .list_product_freshness_split_stems {
    width: 40% !important;
  }
}

cap-button button.cap-button.primary.round {
  border-radius: 16px;
}
/* stylelint-enable */
