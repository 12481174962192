@import 'abstracts';

/* ut = Utility */
.ut-primary-text {
  color: $primary;
}

.ut-color-base {
  color: $primary;
}

.ut-error-text {
  color: $red;
}

/* Util color Error */
.ut-color-error {
  color: $red;
}

/* Util color Success */
.ut-color-success {
  color: $success;
}

/* Util color Warning */
.ut-color-warning {
  color: $orange;
}

/* Util color Warning */
.ut-color-gray {
  color: $gray;
}
