@import 'abstracts';

body .p-datepicker table td.p-datepicker-weeknumber {
  background: transparent;
  box-shadow: none;
}

body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
  padding: 0 18px;
  border: 1px solid var(--cap-primary-color);

  @include straightRight(3px);
}

.dxp-resolver-loader {
  z-index: 99999;
  background-color: #fdfdfd;
}

.cap-loader span.cap-loader-text,
.cap-loader-text:after {
  content: '';
  position: relative;
  margin: 20px auto;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 $primary-bright, 12.73px -12.73px 0 0 $primary-bright, 18px 0 0 0 $primary-bright, 12.73px 12.73px 0 -5px $login-loader, 0 18px 0 -5px $login-loader, -12.73px 12.73px 0 -5px $login-loader, -18px 0 0 -5px $login-loader, -12.73px -12.73px 0 -5px $login-loader;
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px $login-loader, 12.73px -12.73px 0 0 $primary-bright, 18px 0 0 0 $primary-bright, 12.73px 12.73px 0 0 $primary-bright, 0 18px 0 -5px $login-loader, -12.73px 12.73px 0 -5px $login-loader, -18px 0 0 -5px $login-loader, -12.73px -12.73px 0 -5px $login-loader;
  }
  25% {
    box-shadow: 0 -18px 0 -5px $login-loader, 12.73px -12.73px 0 -5px $login-loader, 18px 0 0 0 $primary-bright, 12.73px 12.73px 0 0 $primary-bright, 0 18px 0 0 $primary-bright, -12.73px 12.73px 0 -5px $login-loader, -18px 0 0 -5px $login-loader, -12.73px -12.73px 0 -5px $login-loader;
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px $login-loader, 12.73px -12.73px 0 -5px $login-loader, 18px 0 0 -5px $login-loader, 12.73px 12.73px 0 0 $primary-bright, 0 18px 0 0 $primary-bright, -12.73px 12.73px 0 0 $primary-bright, -18px 0 0 -5px $login-loader, -12.73px -12.73px 0 -5px $login-loader;
  }
  50% {
    box-shadow: 0 -18px 0 -5px $login-loader, 12.73px -12.73px 0 -5px $login-loader, 18px 0 0 -5px $login-loader, 12.73px 12.73px 0 -5px $login-loader, 0 18px 0 0 $primary-bright, -12.73px 12.73px 0 0 $primary-bright, -18px 0 0 0 $primary-bright, -12.73px -12.73px 0 -5px $login-loader;
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px $login-loader, 12.73px -12.73px 0 -5px $login-loader, 18px 0 0 -5px $login-loader, 12.73px 12.73px 0 -5px $login-loader, 0 18px 0 -5px $login-loader, -12.73px 12.73px 0 0 $primary-bright, -18px 0 0 0 $primary-bright, -12.73px -12.73px 0 0 $primary-bright;
  }
  75% {
    box-shadow: 0 -18px 0 0 $primary-bright, 12.73px -12.73px 0 -5px $login-loader, 18px 0 0 -5px $login-loader, 12.73px 12.73px 0 -5px $login-loader, 0 18px 0 -5px $login-loader, -12.73px 12.73px 0 -5px $login-loader, -18px 0 0 0 $primary-bright, -12.73px -12.73px 0 0 $primary-bright;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 $primary-bright, 12.73px -12.73px 0 0 $primary-bright, 18px 0 0 -5px $login-loader, 12.73px 12.73px 0 -5px $login-loader, 0 18px 0 -5px $login-loader, -12.73px 12.73px 0 -5px $login-loader, -18px 0 0 -5px $login-loader, -12.73px -12.73px 0 0 $primary-bright;
  }
}

.dxp-custom-loader {
  z-index: 99999;
  transition: background-color 0.33s ease-out;
}

.cap-loader-image {
  max-width: 100%;
}
