@import 'abstracts';

.login-wrapper {
  .content-right {
    label {
      margin-bottom: 5px;
      font-size: rem-calc(11);
      font-weight: normal;
    }

    .login-form {
      min-height: 300px;

      @include media-breakpoint-down(md) {
        min-height: unset;
      }

      .title {
        margin-bottom: 20px;

        .text {
          font-weight: 600;
        }
      }

      cap-input:first-child {
        margin-bottom: 15px;
      }

      cap-input-switch {
        display: none;
      }

      input::placeholder {
        color: $text-semi-transparent;
      }

      .login-actions {
        margin-top: 15px;

        cap-button button.cap-button {
          padding: 2px 30px;

          .p-button-label {
            padding: 0.43em 1em;
          }
        }

        .login-separator {
          font-size: rem-calc(13);
          font-weight: 600;
        }

        .login-link {
          font-size: rem-calc(12);
        }

        @include media-breakpoint-down(sm) {
          display: block !important; /* stylelint-disable-line declaration-no-important */

          cap-button button.cap-button {
            width: 100%;
          }

          .login-separator {
            display: none;
          }

          .login-link {
            display: block;
            width: 100%;
            text-align: center;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
