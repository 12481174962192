@import 'abstracts';

body {
  background-color: $body-background;
}

.page-content > div {
  padding: 0 $dashboard-page-padding;
}

.content-body {
  position: relative;

  .content-actions--sticky {
    position: sticky;
    bottom: 10px;
    left: 0;
    box-shadow: 0 2px 4px 1px rgba(202, 202, 202, 0.5);
    border: 1px solid $gray;
  }

  > .title-body {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 27px;
    color: $dark-gray;
  }
}

.content-header {
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e4e4e4;

  @include media-breakpoint-down(sm) {
    .float-right {
      width: 100%;
    }

    cap-button button.cap-button:not(.order-full-width) {
      width: calc(50% - 5px);
    }
  }

  h1 {
    display: inline-block;
    font-weight: bold;
    padding-top: 10px;
  }
}

.right-divider-lg,
.right-divider {
  & > div.middle,
  & > div:first-of-type {
    border-right: 1px solid $form-layout-divider;
    padding-right: 15px;
  }

  & > div.middle,
  & > div:last-of-type {
    padding-left: 15px;
  }

  &--no-border {
    & > div:first-of-type {
      padding-right: 15px;
    }

    & > div:last-of-type {
      padding-left: 15px;
    }
  }
}

.right-divider-lg {
  @include media-breakpoint-down(lg) {
    & > div:first-of-type {
      border-right: 0;
      padding-right: 15px;
    }

    & > div.middle,
    & > div:last-of-type {
      padding-left: 15px;
    }
  }
}

div.no-results {
  text-align: left;
  cursor: default;
  background: $white;

  p {
    font-size: rem-calc(13);
  }

  &:hover {
    background-color: transparent;
  }
}

.content-header-wrapper {
  display: flex;
  justify-content: space-between;
}

.content-footer {
  background: $white;
}

.content-footer--sticky {
  @extend .content-footer;
  background: transparent;
  position: sticky;
  bottom: 20px;
  z-index: 1000;

  .card {
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
  }

  &:before {
    content: ' ';
    display: block;
    background: linear-gradient(to bottom, rgba(249, 249, 249, 0), rgba(249, 249, 249, 1));
    width: 100%;
    height: 30px;
  }

  &:after {
    content: ' ';
    display: block;
    background: rgb(249, 249, 249);
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -20px;
  }
}

.no-background {
  app-detail-card {
    .detail-card {
      background-color: transparent;
      border: 0;
      padding: 0;
    }
  }
}

.row-value {
  word-break: break-word;
  overflow-wrap: break-word;
}
