@import 'abstracts';

cap-segmented-buttons {
  &.full-width {
    ul.component {
      width: 100%;

      li:nth-child(1n) {
        min-width: unset;
        width: 100%;
      }
    }
  }

  ul.component {
    border: 1px solid var(--cap-primary-color);
    border-radius: 4px;
    width: max-content;

    @supports not (width: max-content) {
      display: inline-flex !important; /* stylelint-disable-line declaration-no-important */
    }

    li {
      border-right: 1px solid var(--cap-primary-color) !important; /* stylelint-disable-line declaration-no-important */
      color: var(--cap-primary-color);
      text-align: center;

      &.component__item {
        &--first,
        &--last {
          @include flexCentering();
          cursor: pointer;
          font-weight: bold;
          height: 41px;
          min-width: 120px;
          padding: 12px 0;
        }

        &--first {
          @include straightLeft(3px);
        }

        &--last {
          @include straightRight(3px);
        }
      }

      &:last-child {
        border: none;
        border-right: 0 !important; /* stylelint-disable-line declaration-no-important */
      }

      &.active {
        background-color: var(--cap-primary-color);
        color: $white;
      }
    }
  }
}
