@import 'abstracts';

@font-face {
  font-family: $font-light;
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/SourceSansPro-Bold.ttf');
}

@font-face {
  font-family: $font-regular;
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/SourceSansPro-Regular.ttf');
}

@font-face {
  font-family: $font-semi-bold;
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/SourceSansPro-SemiBold.ttf');
}

@font-face {
  font-family: $font-bold;
  font-style: normal;
  font-weight: bold;
  src: url('/fonts/SourceSansPro-Light.ttf');
}
