cap-forgot-password,
cap-login {
  .alert.alert-danger {
    display: none;
  }

  cap-input .form-element .p-inputgroup {
    display: flex;
  }

  cap-reset-password {
    cap-input {
      margin-bottom: 15px;
    }
  }
}
