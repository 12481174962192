@import 'abstracts';

.ibox {
  padding: 20px 0 !important; /* stylelint-disable-line declaration-no-important */

  .ibox-title {
    h5 {
      color: #999;
      font-weight: 600;
    }
  }
}

.config-card {
  i {
    font-size: 3em !important; /* stylelint-disable-line declaration-no-important */
    opacity: 0.5;
  }

  .ibox-title {
    color: #5c5c5c;
  }
}

.back-btn {
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
  font-weight: 600;

  i {
    margin-right: 5px;
  }
}

cap-layout-details {
  .page {
    .main {
      padding-left: 0;

      .headline {
        height: 62px;

        div.float-right,
        cap-button {
          margin-top: -23px;
        }
      }

      .headline__title {
        font-size: rem-calc(24);
        font-weight: 600;
        margin-top: -30px;
      }

      formly-form {
        cap-validator {
          display: none !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }
  }
}

emc-role-list,
emc-role-detail,
emc-translations,
emc-translation-detail {
  > .back-btn {
    position: fixed;
    z-index: 1000;
    top: 0;
    line-height: 78px;

    @include media-breakpoint-down(lg) {
      left: 71px;
      padding: 0 17px;
      border-left: 1px solid #bebebe;
      height: 35px;
      display: flex;
      align-items: center;
      text-decoration: none;
      top: 21px;

      a {
        color: transparent;

        i {
          color: var(--cap-primary-color);
        }
      }
    }

    i {
      margin-right: 3px;
      margin-left: 1px;
    }
  }
}
