@import 'abstracts/variables';

cap-color-picker {
  .colorpicker-input {
    width: calc(100% - 38px);
  }

  .p-colorpicker-preview {
    border-radius: 3px;
  }

  .p-colorpicker-panel {
    border-radius: 3px;
    background-color: $outer-space;
    border-color: $outer-border-light;
  }
}
