@import 'abstracts';

$table-bottom-gutter: 50px;

.cell-table.p-datatable {
  .p-datatable-thead > tr > th {
    display: table-cell !important; /* stylelint-disable-line declaration-no-important */

    @include commonTableCellStyles();
    font-size: 16px;
    background: transparent;
    text-transform: capitalize;

    &.no-border-cell {
      border: none;
    }

    &:first-of-type {
      border-left: none;
    }

    &.no-display {
      display: none;
    }
  }

  .p-datatable-tbody {
    tr {
      background: transparent;

      td {
        display: table-cell !important; /* stylelint-disable-line declaration-no-important */

        @include commonTableCellStyles();

        .cell {
          background: $white;
          border-radius: 3px;
          box-shadow: 0 1px 2px 0 rgba($black, 0.5);
          color: $dark-gray;
          font-size: 14px;
          padding: 8px;
        }

        &.no-border-cell {
          border: none;
        }

        &:first-of-type {
          border-left: none;
        }

        &.toggle-cell {
          text-align: center;
          vertical-align: middle;

          .fa-chevron-up {
            padding-top: 5px;
          }
        }
      }
    }
  }

  .p-datatable-tfoot > tr > td {
    @include commonTableCellStyles();
    font-size: 16px;
    background: transparent;
    display: table-cell !important; /* stylelint-disable-line declaration-no-important */

    &.no-border-cell {
      border: none;
    }

    &:first-of-type {
      border-left: none;
    }
  }
}

.cell-table__bordering {
  .p-datatable-thead > tr > th {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      background-color: $gray;
      height: $table-bottom-gutter;
      right: -1px;
      top: -$table-bottom-gutter;
    }

    &:last-of-type {
      &:before {
        display: none;
      }
    }
  }
}
