.prl-1px {
  position: relative;
  left: 1px;
}

.prr-1px {
  position: relative;
  right: 1px;
}

.prl-4px {
  position: relative;
  left: 1px;
}

.prr-4px {
  position: relative;
  left: 4px;
}
