@import 'abstracts';

.menu-items {
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.1);
  padding-left: 26px;
  background-color: $white;
  border-radius: 5px;

  > *:nth-child(1n + 1).menu-items__item {
    border-bottom: solid 1px #eee;
  }

  .menu-items__item {
    border: none;
    margin-bottom: 0;
    padding: 18.5px 26px 18.5px 0;
    position: relative;
    outline: none;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border: none;
    }

    &:after {
      @include setFontAwesomeProIcon('\f054');
      @include setAbsoluteVerticalCenter();
      color: $gray;
      font-size: 0.6rem;
      right: 13px;
    }

    i {
      font-size: rem-calc(19);
      color: $primary;
      width: 25px;
    }

    .menu-items__content {
      margin-left: rem-calc(16);

      h3 {
        font-weight: 600;
      }
    }
  }
}

::ng-deep cap-button .p-button { /* stylelint-disable-line */
  border: none;

  &:enabled:hover {
    background-color: $primary;
  }
}
