@import 'abstracts';

.g-link {
  color: $primary;
  font-weight: bold;
  cursor: pointer;

  i {
    margin-right: 10px;
  }
}

a.link-navigate-back-page {
  color: $primary;
  font-weight: 600;
  cursor: pointer;

  &.fs-14 {
    font-size: 14px;
  }
}

.clickable {
  cursor: pointer;
}

a.link-disabled {
  pointer-events: none;
  cursor: default;
}
