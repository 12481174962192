cap-input {
  display: block;
  width: 100%;
}

.hidden {
  display: none;
}

.w-max-content {
  width: max-content;
}
