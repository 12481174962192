@import 'abstracts';

.dynamic-accordion {
  .p-accordion .p-accordion-header a {
    @include flexCenteringWithSpaceBetween();
    border-radius: 3px;
    background: $white;
    border: none;
  }

  .p-accordion-toggle-icon {
    order: 2;
  }

  .p-accordion-header-text {
    order: 1;
  }

  .p-accordion-header.p-state-default {
    background: $white;

    a {
      padding: 14px 17px 14px 19px;
    }
  }

  .p-accordion .p-accordion-header:not(.p-disabled).p-state-active:hover a .p-accordion-toggle-icon {
    color: $primary;
  }

  .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
    font-size: 18px;
    color: $primary;
  }

  .p-accordion .p-accordion-header:not(.p-disabled).p-state-active a:hover,
  .p-accordion-header.p-state-default:hover,
  .p-accordion .p-accordion-header:not(.p-disabled).p-state-active a,
  .p-accordion .p-accordion-header a:hover,
  .p-accordion .p-accordion-header:not(.p-state-active):not(.p-disabled):hover a {
    background: $white;
    border: none;
  }

  .p-accordion .p-accordion-content {
    border: none;
  }

  .p-accordion .p-accordion-header:not(.p-disabled).p-state-active a .p-accordion-toggle-icon {
    color: $primary;
  }
}
