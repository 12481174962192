@import 'abstracts';

h1 {
  font-size: 24px;
  font-weight: 600;
  color: $dark-gray;
}

h2 {
  font-size: 18px;
  color: $dark-gray;

  &.subtitle {
    font-weight: bold;
    line-height: 36px;
    display: flex;
    align-content: center;
    margin-bottom: 16px;
    @include truncateAndDots(65%);
  }
}

h3 {
  font-size: 16px;
  color: $dark-gray;
}

h4 {
  font-size: 14px;
  color: $dark-gray;
}

p {
  font-size: 14px;
  color: $dark-gray;
}

h2,
h3 {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 600;
}

$font-styles: (
  regular: regular,
  semibold: semibold,
  bold: bold,
);

@each $name, $font in $font-styles {
  .text-#{$name} {
    font-weight: $font;
  }
}

.text-mat-black {
  color: $dark-gray;
}

.text-primary-color {
  color: var(--cap-primary-color);
}

.strikethrough {
  text-decoration: line-through;
  color: rgba(95, 96, 98, 0.25);
}

* {
  /* stylelint-disable-line */
  outline: none !important; /* stylelint-disable-line declaration-no-important */
}

.no-text-wrap {
  white-space: nowrap;
}

.dont-break-out {
  // /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;

  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  hyphens: auto;
}
