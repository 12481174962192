@import 'abstracts';

.content--form {
  .content__back {
    i {
      margin-right: 10px;
    }
  }

  .content__header {
    padding: 13px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid $form-layout-divider;
    overflow: hidden;

    h1 {
      font-weight: bold;
    }

    .content__heading {
      display: inline-block;
      padding: 6px 0;
    }

    .content__actions {
      float: right;
      @include setRightGutters(20px);
    }
  }
}
