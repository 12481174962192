@import 'abstracts';

.scanner--popup {
  background: $white;
  width: 100%;
  position: fixed;
  bottom: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0 -5px 13px 0 rgba(0, 0, 0, 0.15);
  padding: 25px 18px 18px;
  transition: 0.75s;

  &.active {
    bottom: 0;
  }

  form {
    & > .detail--element {
      min-height: 38px;
      line-height: 38px;
    }

    label {
      font-size: 14px;
      color: $dark-gray;

      span {
        font-size: 12px;
        font-weight: normal;
        color: rgba(51, 51, 51, 0.5);
      }
    }

    p {
      font-size: 16px;
      font-weight: 600;
      color: $primary;

      @include media-breakpoint-down(md) {
        @include truncateAndDots(185px);
      }
    }
  }

  .detail--actions {
    margin-top: 25px;
  }

  cap-textarea {
    .cap-textarea {
      height: 50px;
      line-height: normal;
    }
  }
}

.scanner__grid {
  display: grid;
  grid: auto / 1fr 1fr;
  grid-gap: 15px;

  cap-button {
    .cap-button {
      align-self: stretch;
      height: 100%;
      width: 100%;
    }
  }
}

.scanner__grid-item--stretch {
  grid-column: 1 / 3;
}
