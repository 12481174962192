@import 'abstracts';

/* Mobile subheader tab view menu */
body div.header-tabview-mobile.p-tabview.p-component {
  & > ul.p-tabview-nav {
    box-shadow: $subheader-menu-shadow-box;
    background: $white;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 $dashboard-page-padding;
    display: inline-flex;
    z-index: 100;
    width: 100%;
    flex-wrap: inherit;

    & > li {
      @include flexCentering;
      flex: 1 1 auto;
      text-align: center;
      background-color: $white;
      border: none;
      white-space: nowrap;
      padding: 12px 16px;

      a {
        font-size: 1rem;
        font-weight: 600;
      }

      &.p-highlight a {
        color: var(--cap-primary-color);
      }

      &:active,
      &:focus {
        outline: none;
      }
    }
  }

  div.p-tabview-panels {
    display: none;
  }
}

p-tabView { /* stylelint-disable-line selector-type-case */
  div.mrp-tabs.p-tabview {
    ul.p-tabview-nav li {
      font-size: 16px;

      span {
        line-height: 1.2;
      }
    }
  }
}

// Overwrite capturum css
body {
  .p-tabview-nav-content {
    overflow-x: unset;
    overflow-y: unset;
    scroll-behavior: smooth;
    overscroll-behavior: contain auto;
  }

  .p-tabview {
    .p-tabview-panels {
      border: 1px solid $gray;
      border-radius: 5px;
      background: $white;
    }

    .p-tabview-nav {
      border-bottom: none;

      li.p-highlight {
        background: $white;
        border-color: $gray;
      }
    }
  }
}

.p-tabview-nav-container {
  position: relative;
}

.p-tabview-scrollable .p-tabview-nav-container {
  overflow: hidden;
}

.p-tabview-nav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex: 1 1 auto;
}

.p-tabview-nav-link {
  background: transparent;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  overflow: hidden;
}

.p-tabview-ink-bar {
  display: none;
  z-index: 1;
}

.p-tabview-nav-link:focus {
  z-index: 1;
}

.p-tabview-title {
  line-height: 1;
  white-space: nowrap;
}

.p-tabview-nav-btn {
  position: absolute;
  top: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-tabview-nav-prev {
  left: 0;
}

.p-tabview-nav-next {
  right: 0;
}

.p-tabview-nav-content::-webkit-scrollbar {
  display: none;
}

.p-tabview-close {
  z-index: 1;
}
