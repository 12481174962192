@import 'abstracts';

cap-accordion-card {
  .accordion-card__title {
    min-height: 18px;
  }

  .accordion-card__subtitle {
    font-size: 14px;
    color: $card-subtitle;
  }

  .accordion-card div.ac-header__right {
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    i {
      font-size: 18px;
    }
  }

  .accordion-card__content {
    transition: none;
  }

  .accordion-card__actions {
    i.delete {
      color: $red;
    }

    i.ends-at {
      color: $primary;
    }
  }
}
