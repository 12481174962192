@import 'abstracts';

body p-radioButton { /* stylelint-disable-line */
  display: flex;

  div.p-radiobutton {
    div.p-radiobutton-box {
      border-color: $gray;
      box-shadow: none;
    }

    div.p-radiobutton-box span.p-radiobutton-icon {
      width: 14px;
      height: 14px;
      margin: auto;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
    }

    div.p-radiobutton-box.p-highlight {
      background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
    }

    div.p-radiobutton-box.p-highlight:not(.p-disabled),
    div.p-radiobutton-box.p-highlight:not(.p-disabled):hover {
      border: 1px solid var(--cap-primary-color);
      background: transparent;

      span.p-radiobutton-icon {
        background: var(--cap-primary-color);
      }
    }

    &.success div.p-radiobutton-box.p-highlight:not(.p-disabled),
    &.success div.p-radiobutton-box.p-highlight:not(.p-disabled):hover {
      border: 1px solid $success;

      span.p-radiobutton-icon {
        background: $success;
      }
    }
  }

  label.p-radiobutton-label {
    margin: 0 0 0 13px;
    font-weight: normal;
    font-size: 14px;
  }
}

cpb-radio-input .radio-input-container .p-field-radiobutton {
  display: flex;
  padding-bottom: 10px;

  label {
    margin-left: 13px;
  }
}
