@import 'abstracts';

.cap-overlay-panel.p-overlaypanel {
  border: none;

  .p-overlaypanel-content {
    padding: 0;
    box-shadow: 0 1px 2px 0 rgba($black, 0.5);
  }

  &:before,
  &:after {
    border-style: solid;
  }

  &.primary {
    &.p-overlaypanel-flipped:before,
    &.p-overlaypanel-flipped:after {
      border-top-color: var(--cap-primary-color);
    }

    &:not(.p-overlaypanel-flipped):before,
    &:not(.p-overlaypanel-flipped):after {
      border-bottom-color: var(--cap-primary-color);
    }

    .p-overlaypanel-content {
      border-top: 2px solid var(--cap-primary-color);
    }
  }

  &.header-title-bar {
    &:after,
    &:before {
      left: auto;
      margin-left: auto;
      border-width: 9px;
      right: 7px;
    }
  }
}

/* Blur background: All browsers */
body .p-component-overlay {
  background-color: rgba(255, 255, 255, 0.44);
  -webkit-backdrop-filter: blur(4px); /* stylelint-disable-line */
  backdrop-filter: blur(4px);
}

/* Blur background: Mozilla based browsers */
@-moz-document url-prefix() { /* stylelint-disable-line */
  body .p-component-overlay {
    background-color: rgba(255, 255, 255, 0.88);
    backdrop-filter: blur(4px);
  }
}

.overlay-panel-container {
  .overlay-panel-header {
    padding: 0 14px;
    justify-content: space-between;
    display: flex;
    color: $dark-gray;
    border-bottom: 1px solid $gray;
    align-items: center;

    &.grading-auction {
      align-items: normal;
    }

    .left-side {
      text-align: left;

      div.actions {
        font-size: 17px;
        font-weight: 600;

        &.grading-auction {
          margin-top: 12px;
          margin-bottom: 2px;
        }
      }

      span {
        font-size: 14px;
      }
    }

    .right-side {
      text-align: right;

      i {
        cursor: pointer;
        font-size: 17px;
        padding: 0 10px;
        line-height: 60px;

        &.fa-times {
          color: $dark-gray;
        }

        &.fa-lock,
        &.fa-unlock-alt {
          color: var(--cap-primary-color);
        }
      }
    }
  }

  .overlay-panel-body {
    border-bottom: 1px solid $gray;
    padding: 14px;
  }

  .overlay-panel-footer {
    display: flex;
    width: 100%;
    padding: 14px;

    cap-button {
      width: 50%;

      button.cap-button {
        padding: 2px 0;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .grading-auction-details {
    .overlay-panel-body {
      padding: 14px 0;
    }
  }
}
