@import 'abstracts';

$table-border-transparent: rgba(0, 0, 0, 0.15);
$table-column-border: $body-background;
$dropdown-text-color: #5f6062;
$dropdown-shadow-color: rgba(0, 0, 0, 0.1);
$table-row-selected-border: $green;
$table-row-primary-selected-border: $primary;
$mobile-table-block-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.08);
$border-radius: 3px;

table {
  width: 100%;
}

// Filters
body .p-inputgroup input.p-inputtext.cap-rounded,
body .cap-rounded.cap-dropdown.p-dropdown,
body .cap-rounded.cap-multi-select.p-multiselect {
  border-color: $gray;
  font-weight: 600;
  font-size: 14px;

  @include setPlaceholderStyles(#666, 600);
}

cap-dropdown.has-icon .p-dropdown .p-dropdown-label {
  padding-left: 24px;
}

.cap-rounded.cap-dropdown.p-dropdown .p-dropdown-label,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled):hover,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled).p-focus,
.cap-rounded.cap-multi-select.p-multiselect .p-multiselect-label,
.cap-rounded.cap-multi-select.p-multiselect:not(.p-disabled):hover {
  font-size: 0.813rem;
  font-weight: 600;
}

.cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn {
  min-width: 130px;
  border-color: $gray;

  input {
    padding-left: 21px;
    font-weight: 600;
  }
}

cap-calendar.double-date .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn {
  min-width: 200px;
  width: 100%;
}

.cap-calendar.cap-rounded.p-calendar.p-calendar-range-btn {
  width: 200px;
}

.custom-date-picker .p-datepicker {
  border: unset;
}

.cap-table-filter:not(.table-filters) {
  position: relative;
  margin-bottom: 8px;
  flex-wrap: wrap;

  .filter-icon {
    left: 15px;
  }

  .d-none-reset-filter {
    & ~ .reset-filter {
      display: none;
    }
  }

  form cap-checkbox {
    display: flex;
    align-items: center;
    margin: 0 14px;
    height: 100%;

    @include media-breakpoint-down(md) {
      margin-top: 1em;
    }
  }

  cap-calendar,
  app-adapt-calendar-filter {
    .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn {
      min-width: 220px !important; /* stylelint-disable-line declaration-no-important */
    }

    ~ app-reset-filter > .reset-filter,
    ~ .reset-filter {
      right: 10px;
    }

    .pi {
      font-size: 0.813rem;
    }
  }
}

// Hide filter toggle on desktop
.filters-label {
  display: none !important; /* stylelint-disable-line declaration-no-important */

  @include media-breakpoint-down(md) {
    display: block !important; /* stylelint-disable-line declaration-no-important */
  }
}

// Set filter buttons to full-width on mobile
.w-max-content {
  display: flex;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.table-list > div.row > div.col-lg-10 {
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
}

cap-info-table,
cap-data-table {
  &.white-table {
  .p-datatable {
      .p-datatable-scrollable-header,
      .p-datatable-scrollable-body,
      .p-datatable-wrapper {
        table {
          .p-datatable-tbody {
            .totals-row {
              tr {
                td {
                  background: $body-background;
                }
              }
            }
          }
        }
      }
    }
  }
}

cap-info-table,
cap-data-table {
  &.clickable {
    .p-datatable tbody.p-datatable-tbody tr:not(.break-line):hover td {
      cursor: pointer;
      background: #eee !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .p-datatable {
    position: relative;

    .p-datatable-caption {
      background-color: inherit;
      border: none;

      &:empty {
        display: none;
      }

      .col-md-3 {
        padding: 0;

        .input-group-text {
          background-color: $white;
          border-right: none;
        }

        .form-control {
          padding-left: 0;
          border: 1px solid $table-border-transparent;
          border-left: none;

          &:focus {
            outline: 0;
            box-shadow: none;
            border: 1px solid $table-border-transparent;
            border-left: none;
          }
        }
      }
    }

    .p-datatable-scrollable-header,
    .p-datatable-scrollable-body,
    .p-datatable-wrapper {
      position: relative;
      overflow: unset;

      table {
        border-collapse: separate;
        border-spacing: 0 1em;

        @media screen and (max-width: 640px) {
          .p-datatable-tbody > tr.row-divider {
            box-shadow: $mobile-table-block-shadow;
            background-color: transparent;

            .list-page-table-column {
              background-color: $white;
              padding: 15px;

              &:nth-child(2) {
                border-top-right-radius: $border-radius;
                border-top-left-radius: $border-radius;
              }

              &:last-child {
                border-bottom-right-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
              }
            }
          }
        }

        .p-datatable-thead {
          tr {
            th {
              text-align: left;
              border: none;
              border-bottom: 1px solid $border-color;
              padding: 15px;
              background-color: inherit;
              font-weight: 600;
              color: $dark-gray;

              @include media-breakpoint-between(sm, lg) {
                padding: 15px 5px;
              }

              .divider {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              &:hover:not(.frozen-cell),
              &:focus:not(.frozen-cell) {
                background-color: inherit;
                box-shadow: none;
                outline: none;
              }

              &.table-selectable-col {
                width: 50px;
              }

              &.table-actions-col {
                width: 55px;
              }

              p-sorticon {
                display: inline-flex;
                height: 100%;
                align-items: center;
              }

              span {
                font-size: 14px;
                line-height: 1.1;
                color: $dark-gray;

                i.pi-sort-alt {
                  display: none;
                }

                i.pi-sort-amount-up-alt:before {
                  @include setFontAwesomeProIcon('\f077');
                  color: $dark-gray;
                  font-size: rem-calc(12);
                }

                i.pi-sort-amount-down:before {
                  @include setFontAwesomeProIcon('\f078');
                  color: $dark-gray;
                  font-size: rem-calc(12);
                }
              }

              &.th_totals-column {
                div {
                  @include right-total-column();

                  text-align: right;
                }
              }
            }
          }
        }

        .p-datatable-tbody {
          // /* Green border on selected smaller row */
          @media only screen and (max-width: 640px) {
            tr {
              &.selected {
                td {
                  border-left: 1px solid $table-row-selected-border;
                  border-right: 1px solid $table-row-selected-border;

                  &:first-child {
                    border-top: 1px solid $table-row-selected-border;
                    border-left: 1px solid $table-row-selected-border;
                  }

                  &:last-child {
                    border-bottom: 1px solid $table-row-selected-border;
                    border-right: 1px solid $table-row-selected-border;
                  }
                }
              }
            }
          }

          tr {
            // /* Green border on selected wider row */
            @media only screen and (min-width: 640px) {
              &.selected {
                td {
                  border-bottom: 1px solid $table-row-selected-border;
                  border-top: 1px solid $table-row-selected-border;

                  &:first-child {
                    border-left: 1px solid $table-row-selected-border;
                  }

                  &:last-child {
                    border-right: 1px solid $table-row-selected-border;
                  }
                }
              }

              &.selected-primary {
                td {
                  border-bottom: 1px solid $table-row-primary-selected-border;
                  border-top: 1px solid $table-row-primary-selected-border;

                  &:first-child {
                    border-left: 1px solid $table-row-primary-selected-border;
                  }

                  &:last-child {
                    border-right: 1px solid $table-row-primary-selected-border;
                  }
                }
              }
            }

            td {
              padding: 15px;
              border: none;
              border-bottom: 1px solid transparent;
              border-top: 1px solid transparent;
              background-color: $white;
              word-break: normal;

              &:first-child {
                border-left: 1px solid transparent;
              }

              &:last-child {
                border-right: 1px solid transparent;
              }

              &.actions-wrapper {
                width: 55px;

                & .row-action {
                  text-align: center !important; /* stylelint-disable-line declaration-no-important */
                }

                // Style for dots menu toggler
                .row-action > i {
                  padding: 5px 10px;
                  border-radius: 50%;

                  &:hover {
                    background: $gray;
                  }
                }

                .row-action__icon {
                  margin-right: 12px;
                  display: flex;
                  align-items: center;

                  i {
                    margin: 0;
                  }
                }

                .row-action__info {
                  font-size: 14px;

                  .title {
                    font-weight: 600;
                  }

                  .description {
                    font-size: 12px;
                    margin-top: 4px;
                  }
                }

                i {
                  font-size: 16px;
                  cursor: pointer;

                  &:hover {
                    color: var(--cap-primary-color);

                    &.fa-trash-alt {
                      color: var(--cap-primary-color);
                    }
                  }
                }
              }

              &.list-page-table-column {
                .divider {
                  display: flex;
                  align-items: center;
                }

                @include media-breakpoint-between(sm, lg) {
                  padding-right: 5px;
                  padding-left: 5px;
                }

                .td-value {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-height: 1.1;
                }

                .p-column-title {
                  display: none;

                  @media screen and (max-width: 640px) {
                    display: inline-block;
                  }
                }
              }

              &.display-full-text {
                &.cap-info-table__column,
                &.list-page-table-column .td-value {
                  white-space: inherit;
                  text-overflow: inherit;
                }
              }

              &.selectable-wrapper,
              .cap-info-table__row-select {
                width: 30px;

                .p-checkbox {
                  .p-checkbox-box {
                    width: 20px;
                    height: 20px;
                    border-radius: 2px;
                    position: relative;
                    background: transparent;

                    &.p-highlight {
                      border-color: $success;
                      background-color: $success;
                    }

                    .p-checkbox-icon {
                      position: absolute;
                      left: 2px;
                      font-size: 16px;

                      &.pi-check:before {
                        @include setFontAwesomeProIcon('\f0c8');
                        color: $success;
                      }
                    }
                  }
                }
              }

              .p-column-title {
                display: none;
              }

              :not(.totals-row) > tr > td > cap-cell-data {
                span:not(.error-text):not(.p-button-label) {
                  font-size: 0.88rem;
                  color: $dropdown-text-color;
                }
              }

              span.error-text {
                color: $red;
              }

              &.totals-column {
                cap-cell-data {
                  @include right-total-column();
                }
              }
            }
          }

          .totals-row > tr > td > cap-cell-data {
            font-size: 1rem;
          }

          .totals-row > tr > td {
            &:not(:first-child) {
              cap-cell-data {
                @include right-total-column();
              }
            }
          }
        }
      }
    }

    .p-datatable-loading-icon.pi-spinner {
      font-size: 2em;
      font-weight: 600;
      color: $spinner-color;
    }

    .p-datatable-loading-content {
      @include flexCentering;
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      background: $white;
      opacity: 0.5;
      z-index: 1;
      font-size: 20px;
      left: 0;
      top: 0;
      margin: 0;
    }
  }

  p-paginator.p-element .p-paginator.p-component {
    border: none;
    background-color: inherit;
    display: block;
    text-align: right;
    float: right;
    width: 100%;
    padding: 15px 0;
    position: relative;

    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center !important; /* stylelint-disable-line declaration-no-important */
    }

    .p-paginator-left-content {
      display: flex;
      padding-top: 15px;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;

      @include media-breakpoint-down(sm) {
        position: relative;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 15px;
        width: 100%;
      }

      > span:first-child {
        margin-left: 0 !important; /* stylelint-disable-line declaration-no-important */
      }

      cap-dropdown {
        height: 32px;
        margin-right: 0.5em;
        border-radius: 3px;

        .p-dropdown.num-rows-dropdown {
          height: 32px;
          border: 1px solid $input-border-color;
          margin-right: 0.5em;
          border-radius: 3px;

          .p-dropdown-trigger,
          .p-dropdown-label {
            height: auto;
            border-radius: 3px;
            padding-right: 0;
          }

          .p-dropdown-label {
            padding-top: 6px;
          }
        }
      }
    }

    .p-paginator-element {
      padding: 9px 7px;
      align-items: center;
      display: inline-flex;
      border: 1px solid $border-color;
      background-color: $white;
      font-size: 14px;
      font-weight: normal;

      &:last-child {
        border-right: 1px solid $border-color;
      }

      &.p-paginator-prev,
      &.p-paginator-next {
        anglelefticon,
        anglerighticon {
          display: none;
        }
      }

      &.p-highlight {
        background: var(--cap-primary-color);
        color: $white;
      }
    }

    .p-paginator-pages {
      .p-paginator-element {
        padding: 9px 13px;
        border-left: 0;

        &.p-state-active {
          color: $white;
        }

        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    .p-paginator-next {
      margin-left: -2px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-right: 1px solid $light-greyish-blue !important; /* stylelint-disable-line declaration-no-important */
    }

    .p-paginator-prev {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-right-width: 2px !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .cap-rounded.cap-dropdown.p-dropdown,
  .cap-rounded.cap-multi-select.p-multiselect {
    padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */

    .p-dropdown-trigger,
    .p-multiselect-trigger {
      height: calc(var(--cap-input-height) - 2px);
      margin-top: 0;
    }
  }

  .characteristics {
    white-space: pre;
    word-spacing: 5px;
  }
}

table.table-footer-actions {
  tbody > tr {
    background-color: $white;

    td {
      padding: 15px;
    }
  }
}

div.table-list > .row {
  overflow: hidden;
}

div.table-filters-row-wrap {
  @include setRightGutters(10px);
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  align-content: center;
  align-items: center;

  & > div {
    position: relative;
  }

  cap-input .p-inputgroup {
    height: 40px;

    input:nth-child(1n),
    input:nth-child(1n):enabled:hover:not(.p-state-error),
    input:nth-child(1n):enabled:focus:not(.p-state-error) {
      height: 38px !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  div.form-element,
  button {
    width: max-content;
  }
}

@include media-breakpoint-down(md) {
  .filters-label {
    display: block;
    margin-bottom: 10px;
    font-size: rem-calc(16);
    color: $dark-gray;
  }

  div.table-filters-row-wrap {
    @include setLeftGutters(0);
    display: block;

    button,
    div.form-element,
    .w-max-content,
    .cap-calendar.cap-rounded.p-calendar.p-calendar-range-btn {
      width: 100%;
    }

    .cap-table-filter {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
}

/* Fix some table-form-row dropdown issues */
td div.divider cap-dropdown {
  width: 100%;

  .p-helper-clearfix:before,
  .p-helper-clearfix:after {
    content: '';
    display: block;
  }
}

cap-data-table {
  .table-filters > div:nth-child(2) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.table-list > .table-filters > .mt-3 {
  margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.custom-width {
  width: 100px;

  cap-input {
    display: contents;
  }
}

.th_flight-custom-width { /* stylelint-disable-line */
  width: 40px !important; /* stylelint-disable-line declaration-no-important */

  cap-input {
    display: contents;
  }
}

.row-action__items--open {
  z-index: 9;
  right: 8px;
}

.p-datatable-scrollable-body {
  td.actions-wrapper {
    padding: 0 15px !important; /* stylelint-disable-line declaration-no-important */
  }
}

app-base-data-list {
  td.actions-wrapper {
    padding: 0 15px !important; /* stylelint-disable-line declaration-no-important */
  }
}

.p-datatable-scrollable-header {
  th.columns-list {
    position: static !important; /* stylelint-disable-line declaration-no-important */

    i {
      background-color: $body-background;
      padding: 15px;
      padding-top: 20px;
    }
  }
}

.cap-table.p-datatable .p-datatable-thead > tr > th:hover.frozen-cell {
  background-color: inherit;
}

.p-datatable-unfrozen-view {
  @media screen and (max-width: 40em) {
    .empty-message span {
      margin-left: auto !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.p-datatable-frozen-view .empty-message span {
  color: transparent !important; /* stylelint-disable-line declaration-no-important */
}

.cap-table-filters .cap-table-filter {
  max-height: 38px;

  .cap-multi-select,
  .p-multiselect {
    padding-left: 0.5rem;
  }

  &.custom-select-resettable {
    .cap-dropdown.p-dropdown .p-dropdown-label {
      padding-left: 40px;
      padding-right: 30px !important; /* stylelint-disable-line declaration-no-important */
    }

    cap-multi-select.has-icon .p-multiselect .p-multiselect-label {
      padding-left: 33px;
    }
  }

  cap-checkbox {
    display: flex;
    align-items: center;
    margin: 0 14px;
    height: 100%;

    @include media-breakpoint-down(md) {
      margin-top: 1em;
    }
  }

  cap-input {
    .p-inputgroup input.p-inputtext {
      padding-right: 30px !important; /* stylelint-disable-line */
    }

    ~ .reset-filter {
      right: 10px;
    }
  }

  .reset-filter {
    top: -1px;
    right: 30px;
  }
}

@media screen and (max-width: 40em) {
  .cap-table.p-datatable-responsive .p-datatable-tbody > tr > td {
    width: 100% !important; /* stylelint-disable-line declaration-no-important */
  }
}

cap-info-table {
  .p-datatable-scrollable-header,
  .p-datatable-scrollable-body,
  .p-datatable-wrapper {
    table {
          .totals-row {
            tr {
              td {
                background-color: $body-background;
              }
            }
          }
    }
  }

  .cap-info-table.p-datatable .p-datatable-tbody td.cap-info-table__actions-column--single {
    position: absolute;
    right: 0;
  }

  @include media-breakpoint-down(md) {
    .cap-info-table.p-datatable .p-datatable-tbody td .cap-info-table__actions-column--flexed {
      border-bottom: none;
    }
  }

  &.info-table.info-table--outline {
    .cap-info-table.p-datatable {
      .p-datatable-thead {
        tr {
          th {
            font-weight: 600;
            color: rgba(58, 58, 58, 0.5);
          }
        }
      }

      .p-datatable-tbody {
        tr {
          td {
            background: $body-background;
            border-top: 1px solid $gray;
            border-bottom: 1px solid $gray;
            height: 56px;
            font-size: 16px;

            &:first-of-type {
              border-left: 1px solid $gray;
            }

            &:last-of-type {
              border-right: 1px solid $gray;
            }
          }
        }
      }
    }
  }

  .p-datatable {
    table .p-datatable-tbody {
      .disable-checkbox {
        p-tablecheckbox {
          pointer-events: none;
        }

        .p-checkbox .p-checkbox-box {
          border-color: $light-gray !important; /* stylelint-disable-line declaration-no-important */
          background: $light-gray !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }
  }

  .characteristics {
    .cap-cell-tooltip-container span {
      white-space: pre;
      word-spacing: 5px;
    }
  }

  .empty-message-row {
    td {
      @media screen and (min-width: 768px) and (max-width: 960px) {
        display: table-cell !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }
}

cap-info-table.hideSelectAll .p-datatable .p-datatable-wrapper table .p-datatable-thead tr th p-tableheadercheckbox {
  display: none;
}

.cap-info-table.p-datatable .p-datatable-tbody tr.break-line {
  position: relative;

  td {
    height: 0 !important; /* stylelint-disable-line declaration-no-important */
    line-height: 0;
    border: 0 !important; /* stylelint-disable-line declaration-no-important */

    & > * {
      display: none;
      border-bottom: 1px solid $gray;
    }
  }

  td:nth-child(1) {
    display: none;
  }

  td:nth-child(5) {
    display: initial !important; /* stylelint-disable-line declaration-no-important */
    border-bottom: 1px solid $gray !important; /* stylelint-disable-line declaration-no-important */
    z-index: 1;
    position: absolute;
    width: 100%;
    left: 0;
    background: transparent;
    bottom: 13px;
  }

  td:nth-child(6) {
    display: initial !important; /* stylelint-disable-line declaration-no-important */
    background-color: $white;
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    border: 0;

    span.cap-cell-tooltip-container {
      display: initial !important; /* stylelint-disable-line declaration-no-important */

      span.cap-cell-tooltip {
        color: $primary;
      }
    }

    & > * {
      display: initial;
      background-color: $white;
      z-index: 2;
      padding: 0 15px;
      font-weight: bold;
      border: 0;
      position: relative;
    }
  }
}

// Flights

.flights {
  display: flex;
  flex-wrap: wrap;

  .flight {
    margin-right: 15px;
    width: 80px;
  }
}

.flight-info {
  position: relative;
  display: flex;
  margin: 3px 0;

  .flight-number {
    position: relative;
    width: 70px;
    height: 18px;

    span {
      position: absolute;
      font-size: 16px !important; /* stylelint-disable-line declaration-no-important */
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 70px;
      height: 18px;
      font-weight: normal;
      background: $body-background;
    }

    &:hover span {
      z-index: 10;
      width: auto;
      overflow: unset;
    }
  }

  .flight-status {
    color: $text-semi-transparent;
    font-size: 13px;
    font-weight: normal;
  }

  i {
    display: block;
    margin: auto 5px auto 0;
    max-width: 30px;
    height: 25px;
    font-size: 16px;

    &.red {
      color: $red;
    }

    &.green {
      color: $green;
    }
  }

  .flight-info-sub-status {
    position: absolute;
    display: none;
    margin: auto;
    padding: 10px;
    z-index: 10;
    background: $white;
    box-shadow: 0 10px 20px 0 #00000021;
    border-radius: 5px;
    bottom: -40px;
    left: 0;
    white-space: nowrap;

    i {
      height: auto;
    }
  }

  &:hover .flight-info-sub-status {
    display: block;
  }
}

@include media-breakpoint-down(lg) {
  .cap-info-table.p-datatable .p-datatable-tbody td {
    min-height: 50px;
    height: auto;
  }

  .cap-cell-tooltip-container span {
    white-space: normal;
    overflow: initial;
  }
}

.cap-table.p-datatable {
  .p-datatable-thead {
    .table-header {
      p-sorticon {
        .p-icon-wrapper {
          .p-sortable-column-icon {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

sortalticon::before {
  @include setFontAwesomeProIcon('\f0dc');
  color: $gray;
}

sortamountupalticon::before {
  @include setFontAwesomeProIcon('\f0de');
}

sortamountdownicon::before {
  @include setFontAwesomeProIcon('\f0dd');
}

sortalticon,
sortamountupalticon,
sortamountdownicon {
  svg {
    display: none !important;
  }
}

sortalticon::before,
sortamountupalticon::before,
sortamountdownicon::before {
  font-size: 13px;
}
