@import 'abstracts';

.p-splitbutton {
  padding: 0;
  box-shadow: 0 2px 4px 0 rgba(99, 49, 137, 0.24);

  .p-button {
    padding: 4px 10px;
    width: calc(100% - 26px);
    font-weight: bold;

    &.p-corner-left:focus,
    &.p-corner-left:active,
    &.p-corner-right:focus,
    &.p-corner-right:active {
      outline: none;
      box-shadow: none;
    }

    &.p-corner-left {
      box-shadow: none;
      border-top-left-radius: 3px;

      .p-button-icon-left {
        padding-left: 3px;
        font-size: 16px;
      }

      .p-button-text {
        padding-left: 4em;
      }
    }

    &.p-corner-right {
      box-shadow: none;
      border-bottom-right-radius: 3px;

      .p-button-icon-left {
        left: 0;
      }
    }
  }

  &.primary-outline {
    .p-button {
      background: none;
      color: var(--cap-primary-color);

      &.p-corner-left {
        border: 1px solid var(--cap-primary-color);
        border-right: none;
      }

      &.p-corner-right {
        border: 1px solid var(--cap-primary-color);
        border-left: none;
      }
    }
  }

  &:hover .p-button,
  &:hover .p-button:enabled {
    background: var(--cap-primary-color);
    color: $white;
  }

  &.p-buttonset.btn-primary .p-button {
    background-color: var(--cap-primary-color);
  }
}
