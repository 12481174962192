$aligns: left, center, right;
$align-items: flex-start, flex-end, center, stretch, baseline;
$cursors: pointer, auto, not-allowed, none;
$pointer-events: none, auto;

@each $align in $aligns {
  .justify-#{$align} {
    justify-content: $align;
  }
  .text-#{$align} {
    text-align: $align;
  }
}

@each $align-item in $align-items {
  .align-#{$align-item} {
    align-items: $align-item;
  }
}

.flex-wrap {
  flex-wrap: wrap;

  & > * {
    margin: 0 0 0.25em 0.25em;
  }
}

@each $cursor in $cursors {
  .cursor-#{$cursor} {
    cursor: $cursor !important; /* stylelint-disable-line declaration-no-important */
  }
}

@each $pointerEvent in $pointer-events {
  .pe-#{$pointerEvent} {
    pointer-events: $pointerEvent !important; /* stylelint-disable-line declaration-no-important */
  }
}
