@import 'abstracts';

.harvest.card {
  background: $white;
  box-shadow: $card-shadow;
  border-radius: 3px;

  @include media-breakpoint-up(sm) {
    max-width: 400px;
  }

  .card-header {
    background: $white;
    border: none;

    .header-title {
      font-size: rem-calc(16);
    }

    .header-sub-title {
      font-size: rem-calc(14);
    }
  }

  .card-body {
    > .row {
      padding: 0 1rem;
    }

    .row .col {
      font-size: rem-calc(13);
    }
  }

  .card-footer {
    background: $white;
    border: none;

    > .row {
      padding: 0 1rem;
    }

    .row .col {
      font-size: rem-calc(13);
    }
  }
}
