@import 'abstracts';

body {
  form {
    .form-group {
      label:not(.p-checkbox-label) {
        margin-bottom: 0.25rem;
      }

      .p-checkbox-label {
        margin-bottom: 0;
      }

      .ng-valid {
        .validator {
          display: none;
        }
      }

      .validator:not(.show) {
        display: none;
      }

      .error-text {
        color: var(--error-color);
        font-size: rem-calc(10);
      }
    }

    .form-group-inline {
      margin-bottom: 1rem;
      align-items: center;

      > .col {
        font-size: 16px;
        font-weight: 600;
        color: $mid-gray;

        @include media-breakpoint-down(md) {
          margin-bottom: 8px;
        }
      }
    }
  }
}

.row-label {
  font-weight: 600;
  color: $dark-gray;
}

.table-filters {
  &.cap-table-filter {
    .row {
      [class*='col-'] {
        position: relative;
      }
    }
  }
}
