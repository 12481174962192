@import 'abstracts';

@mixin commonInfoTableStyles() {
  border: none;
  height: 50px;
  padding: 0 10px;
}

body .info-table.p-datatable {
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    table-layout: auto;
  }

  .p-datatable-thead > tr > th {
    @include commonInfoTableStyles();
    font-size: 16px;
    background: transparent;
    border-bottom: 1px solid $gray;
    font-weight: 600;

    &.no-border-cell {
      border: none;
    }

    &:first-of-type {
      border-left: none;
    }
  }

  .p-datatable-tbody {
    tr {
      background: $white;
      border-radius: 3px;

      td {
        @include commonInfoTableStyles();

        &:first-of-type {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &:last-of-type {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
  }

  &.info-table--outline {
    .p-datatable-thead > tr > th {
      font-weight: 600;
    }

    .p-datatable-tbody {
      tr {
        background: $body-background;

        td {
          border-top: 1px solid $gray;
          border-bottom: 1px solid $gray;
          height: 56px;
          font-size: 16px;

          &:first-of-type {
            border-left: 1px solid $gray;
          }

          &:last-of-type {
            border-right: 1px solid $gray;
          }
        }
      }
    }
  }

  &.info-table--headless {
    .p-datatable-thead {
      opacity: 0;

      th {
        height: 0;
        font-size: 0;
      }
    }
  }

  &.info-table--empty {
    table {
      border-spacing: 0 5px;
      margin-top: -5px;
    }

    .p-datatable-tbody {
      tr {
        background: transparent;

        td {
          border-color: transparent;

          &:first-of-type,
          &:last-of-type {
            border: transparent;
          }
        }
      }
    }
  }

  &.info-table-record {
    .p-datatable-thead {
      th {
        color: $outer-space;
        font-size: 16px;
        font-weight: 600;
        opacity: 0.5;
        text-align: left;
      }
    }
  }

  &.info-table-characteristics {
    .p-datatable-thead {
      th {
        text-align: left;
      }
    }
  }

  &.table-widget {
    .p-datatable-tbody {
      tr {
        td {
          width: 75%;
          height: 30px;
          font-size: 14px;

          &:last-of-type {
            width: 25%;
            text-align: right;
          }
        }
      }
    }
  }
}

cap-row-action {
  .row-action > i {
    padding: 5px 10px;
    border-radius: 50%;
    color: var(--primary-text);
    font-size: 1rem;

    &:hover {
      background: $gray;
    }
  }
}

:not(.totals-row) > tr > td > cap-cell-data {
    color: var(--primary-text);
    font-size: 0.88rem;
}
