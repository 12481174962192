@import 'abstracts';

body cap-checkbox {
  .form-element {
    label {
      display: inline-block;
      font-weight: 500;
      margin-left: 0.5em;
    }
  }

  p-checkbox {
    outline: 0 none;
  }

  .green.p-checkbox .p-checkbox-box.p-highlight,
  .green.p-checkbox .p-checkbox-box.p-state-hover,
  .green.chkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: $green !important; /* stylelint-disable-line declaration-no-important */
    background: $green !important; /* stylelint-disable-line declaration-no-important */
  }

  .green.p-checkbox .p-checkbox-box.p-highlight span:before,
  .green.p-checkbox .p-checkbox-box.p-state-hover span:before {
    color: $green !important; /* stylelint-disable-line declaration-no-important */
  }

  .green.p-checkbox .p-checkbox-box.p-focus {
    box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
  }

  .readonly {
    .p-checkbox-box {
      opacity: 1 !important; /* stylelint-disable-line declaration-no-important */
      box-shadow: none;
    }

    &.gray .p-checkbox-box {
      background: $light-gray;
      border-color: $light-gray;
    }
  }
}

p-checkbox {
  outline: none;
}

/* Force checkboxes to stay the same size when hovering with mouse */
p-tablecheckbox .p-checkbox-icon {
  font-size: 14px !important; /* stylelint-disable-line declaration-no-important */
}
