@import 'abstracts';

.radio-row__item {
  @include card-layout(12px 11px);
  box-shadow: none;
  border: 1px solid $gray;

  &.radio-row--active {
    border-color: $success;
  }

  &.radio-row--no-results {
    min-height: 20px;
    line-height: 20px;
    text-align: center;
  }
}
